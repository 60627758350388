/**
 * 时间采集管理
*/
class TimeCollectionManager {
  static instance = null
  constructor() {
    // 单例模式
    if (TimeCollectionManager.instance) {
      return TimeCollectionManager.instance
    }
    this.timeCollections = {} // 记录时间段
    this.recordPointTimes = {} // 记录时间点
    TimeCollectionManager.instance = this
  }
  // 采集多个时间段
  setMulTimes(data, options){
    const { unit = 'ms' } = options || {} 
    Object.entries(data).forEach(([name, val])=>{
      if(name && typeof val === 'number'){
        this.timeCollections[name] = unit === 's' ? val * 1000 : val //单位转换成毫秒
      }
    })
  }
  // 设置时间段
  setTime(name, val){
    if(name && typeof val === 'number'){
      this.timeCollections[name] = val
    }
  }
  // 重置
  reset(){
    this.timeCollections = {}
  }

  // 记录时间点
  recordPointTime(name, val){
    if(name && typeof val === 'number'){
      this.recordPointTimes[name] = val
    }
  }

  // 重置时间点
  resetPointTime(){
    this.recordPointTimes = {}
  }

}

export default new TimeCollectionManager()
